import '@total-typescript/ts-reset';
import '@fontsource-variable/inter';
import '@/config/sentry.ts';
import 'react-loading-skeleton/dist/skeleton.css';
// import '@/opentelemetry.ts';
import '@/features/auth/utils/auth.interceptor.ts';

import { CssBaseline, CssVarsProvider, GlobalStyles } from '@mui/joy';
import { ErrorBoundary } from '@sentry/react';
import { QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { RouterProvider } from '@tanstack/react-router';
import { ConfirmProvider } from 'mui-joy-confirm';
import { StrictMode } from 'react';
import ReactDOM from 'react-dom/client';

import { queryClient } from '@/config/query-client.ts';
import theme from '@/config/theme.ts';
import { router } from '@/routes/router.ts';

const enableDevtools = false;

const root = window.document.querySelector('#root');
if (!root) throw new Error('No root element found');

function FallbackComponent() {
  return <div>An error has occurred</div>;
}

ReactDOM.createRoot(root).render(
  <StrictMode>
    <QueryClientProvider client={queryClient}>
      <ErrorBoundary fallback={<FallbackComponent />}>
        <CssVarsProvider defaultMode="system" theme={theme}>
          <CssBaseline />

          <GlobalStyles
            styles={{
              ':root': {
                '--Form-maxWidth': '800px',
                '--Transition-duration': '0.4s', // set to `none` to disable transition
              },
            }}
          />

          <ConfirmProvider>
            <RouterProvider router={router} />
          </ConfirmProvider>
          {/* eslint-disable-next-line @typescript-eslint/no-unnecessary-condition */}
          {enableDevtools && <ReactQueryDevtools initialIsOpen={false} />}
        </CssVarsProvider>
      </ErrorBoundary>
    </QueryClientProvider>
  </StrictMode>,
);
