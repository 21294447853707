import { useMutation, useQuery } from '@tanstack/react-query';
import axios from 'axios';

import { config } from '@/config/config.ts';
import { queryClient } from '@/config/query-client.ts';
import type { RegistrationFormData } from '@/features/auth/types/registration.schema.ts';
import {
  UserSchema,
  type UserType,
} from '@/features/auth/types/user.schema.ts';

export const getAuthUser = () => {
  return queryClient.getQueryData<UserType>(['authUser']);
};

export const useUser = () => {
  const authUser = getAuthUser();
  return useQuery<UserType>({
    enabled: !authUser,
    initialData: authUser,
    queryFn: async () => {
      return await fetchUser();
    },
    queryKey: ['authUser'],
  });
};

export const fetchUser = async (): Promise<UserType> => {
  const result = await axios.get('/api/v1/auth/me');
  return UserSchema.parse(result.data);
};

export const useAuth = () => {
  const loginMutation = useMutation({
    mutationFn: ({ email, password }: { email: string; password: string }) => {
      return axios.post(config.apiUrl + 'auth/login', {
        email,
        password,
      });
    },
    onSuccess: async () => {
      await fetchUser();
    },
  });

  const oauthMutation = useMutation({
    mutationFn: (provider: string) => {
      return axios.get(config.apiUrl + `auth/${provider}/authorize`);
    },
  });

  const logoutMutation = useMutation({
    mutationFn: () => {
      return axios.delete(config.apiUrl + 'auth/logout');
    },
    // onSuccess: async () => {
    //   await fetchUser();
    // },
  });

  const registerMutation = useMutation({
    mutationFn: (data: RegistrationFormData) => {
      return axios.post(`${config.apiUrl}auth/register`, data);
    },
    onSuccess: async () => {
      await fetchUser();
    },
  });

  return {
    loginMutation,
    logoutMutation,
    oauthMutation,
    registerMutation,
  };
};
