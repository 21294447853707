import { createRouter } from '@tanstack/react-router';
import React from 'react';

import { config } from '@/config/config.ts';
import {
  authRoute,
  loginRoute,
  signUpRoute,
} from '@/features/auth/routes/auth.routes.tsx';
import {
  accountRoute,
  apiKeysRoute,
  billingRoute,
  buyRoute,
  dashboardRoute,
  paymentsRoute,
  paymentSuccessRoute,
  protectedRoutes,
  transactionsRoute,
  transcriptionRoute,
} from '@/routes/protected.routes.tsx';
import { apiRoute } from '@/routes/public.routes.tsx';

import { rootRoute } from './root.route.tsx';

/* prettier-ignore */
const routeTree = rootRoute.addChildren([
  authRoute.addChildren([loginRoute, signUpRoute]),
  protectedRoutes.addChildren([
    dashboardRoute,
    accountRoute,
    apiKeysRoute,
    transcriptionRoute,
    billingRoute.addChildren([
      buyRoute,
      paymentSuccessRoute,
      paymentsRoute,
      transactionsRoute,
    ]),
  ]),
  apiRoute,
]);
/* prettier-ignore-end */

export const router = createRouter({ routeTree });

declare module '@tanstack/react-router' {
  interface Register {
    router: typeof router;
  }
}

// TanStack devtools
export const TanStackRouterDevtools =
  config.env === 'production'
    ? () => null // Render nothing in production
    : React.lazy(() =>
        // Lazy load in development
        import('@tanstack/router-devtools').then((response) => ({
          default: response.TanStackRouterDevtools,
          // For Embedded Mode
          // default: res.TanStackRouterDevtoolsPanel
        })),
      );
